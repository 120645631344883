import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["frame"];

  connect() {
    console.log("SyncScroll Controller connected", this.element);
    this.frameTargets.forEach((frame) => {
      frame.addEventListener('scroll', this.syncScroll.bind(this));
    });
  }

  syncScroll(event) {
    const sourceElement = event.target;
    const otherFrames = this.frameTargets.filter(frame => frame !== sourceElement);
    console.log(otherFrames);
    otherFrames.forEach(frame => frame.scrollLeft = sourceElement.scrollLeft);
  }

  disconnect() {
    this.frameTargets.forEach((frame) => {
      frame.removeEventListener('scroll', this.syncScroll.bind(this));
    });
  }
}
